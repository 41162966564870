<template>
    <div class="associates-subscriptions__associates-allowed" v-if="showStatus">
        <img src="@/assets/icons/vuesax/linear/info-circle.svg" alt="">
        You have reached the limit of associates allowed, delete associates or upgrade your subscription
    </div>
</template>

<script setup>
import { associatesSubscriptionsServices } from '../Services/AssociatesSubscriptionsServices'
import { ref, onBeforeMount } from 'vue'

const { checkAssociatesAllowed } = associatesSubscriptionsServices()
const showStatus = ref(false)

onBeforeMount(async () => {
    const response = await checkAssociatesAllowed()
    showStatus.value = response.data
})
</script>