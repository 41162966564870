import { httpRequests } from '../../../helpers/HttpRequests.js'

export function associatesSubscriptionsServices() {
    const { getRequest, postRequest } = httpRequests()

    function getSubscriptionsService() {
        return getRequest('/web/stripe/associates_subscriptions')
    }

    function updateSubscriptionService(subscription) {
        return postRequest(`/web/stripe/associate_subscription_update`, subscription)
    }

    function checkAssociatesAllowed() {
        return getRequest('/web/associates/allowed')
    }

    return { getSubscriptionsService, updateSubscriptionService, checkAssociatesAllowed }
}