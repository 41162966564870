<template>
    <div class="associates-subscriptions__cards">
        <div class="associates-subscriptions__card-body" v-for="(subscription, index) in subscriptions" :key="index">
            <div :class="[
                'associates-subscriptions__card',
                subscription.isSubscribed ? 'associates-subscriptions--current-subscription' : 'associates-subscriptions--default-color'
            ]">
                <h3 class="associates-subscriptions__card-title">{{ subscription.nickname }}</h3>
                <p class="associates-subscriptions__card-description">{{ subscription.metadata.description }}</p>
                <h3 class="associates-subscriptions__card-price">
                    {{ currency(subscription.unit_amount_decimal) }} USD/Month
                </h3>
            </div>

            <button class="button button--success" v-if="subscription.isSubscribed">
                <img src="@/assets/icons/vuesax/linear/tick-circle.svg" alt="" width="20" height="20">
                Current Subscription
            </button>

            <button class="button button--default-color" 
                @click="updateSubscription(subscription.id)" 
                :disabled="textLoader"
                v-if="!subscription.isSubscribed && selectedPriceId !== subscription.id">
                <span>Subscribe</span>
            </button>

            <ButtonTextLoader 
                loader-text="Subscribing" 
                button-class="button button--default-color" 
                :is-loading="textLoader" v-if="selectedPriceId === subscription.id" 
            />
        </div>
    </div>

    <ContentLoader :hidden-loader="hiddenLoader" />

    <Notifications ref="notification" />
</template>

<script setup>
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import ContentLoader from '@/common/ContentLoader.vue'
import { associatesSubscriptionsServices } from '../Services/AssociatesSubscriptionsServices'
import { ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import Notifications from '@/common/Notifications.vue'

const { getSubscriptionsService, updateSubscriptionService } = associatesSubscriptionsServices()
const subscriptions = ref([])
const textLoader = ref(false)
const selectedPriceId = ref(null)
const router = useRouter()
const hiddenLoader = ref(false)
const { sweetSuccess } = sweetAlerts()
const notification = ref(null);

onBeforeMount(() => {
    getSubscriptions()
})

async function getSubscriptions() {
    const response = await getSubscriptionsService()

    if (response.hasErrors) return console.error(`Error getting Stripe subscriptions: ${response.hasErrors}`)

    subscriptions.value = response.data.availableSubscriptions
    hiddenLoader.value = true
}

function currency(value) {
    const parseCurrency = parseInt(value.substring(0, value.length - 2));
    return parseCurrency.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

async function updateSubscription(priceId) {
    textLoader.value = true
    selectedPriceId.value = priceId
    
    const price = {priceId: priceId}
    const response = await updateSubscriptionService(price)

    if (response.hasErrors) {
        textLoader.value = false
        selectedPriceId.value = null
        
        notification.value.sendNotification('error', 'Error', response.message)
        
        return console.error(`Error updating subscription: ${response.hasErrors}`)
    } 

    sweetSuccess('Subscription Updated.')

    return router.push('/')
}
</script>